const modalTitle = '友情提示'
const SUCCESS = 'SUCCESS'
const FAILURE = 'FAILURE'
const REQUEST = 'REQUEST'
const ERROR_UNAUTHORIZED = 'ERROR_UNAUTHORIZED'
export const RECENT_SIZE = 5
export const DELAY = 200
const globalConst = {
  doSearch: 'doSearch',
  updateSearchValue: 'updateSearchValue',
  doLoadJsConfig: 'doLoadJsConfig',
  doListCatalogs: 'doListCatalogs',
  doRecommend: 'doRecommend',
  doGetDetail: 'doGetDetail',
  top4Skus: 'top4Skus',
  doListSkus: 'doListSkus',
  doListTop4Skus: 'doListTop4Skus',
  recommend: 'recommend',

  doCartAddSku: 'doCartAddSku',
  doGroupBuyCartAddSku: 'doGroupBuyCartAddSku',
  doLoadDashboard: 'doLoadDashboard',
  doListAddress: 'doListAddress',
  doCartChangeSku: 'doCartChangeSku',
  doBuy: 'doBuy',

  doLoadProfile: 'doLoadProfile',

  loadProvinceList: 'loadProvinceList',
  loadCityList: 'loadCityList',
  doSelectProvince: 'doSelectProvince',
  doSelectCity: 'doSelectCity',
  resetCity: 'resetCity',
  doAddAddress: 'doAddAddress',
  doEditAddress: 'doEditAddress',
  cliearAddressList: 'cliearAddressList',
  doDelAddress: 'doDelAddress',

  doDistrictsByName: 'doDistrictsByName',
  doCitiesByName: 'doCitiesByName',

  doSetNickName: 'doSetNickName',
  doSetSex: 'doSetSex',
  doSetMobile: 'doSetMobile',
  doSetCity: 'doSetCity',
  setCity: 'setCity',
  doBindImbcode: 'doBindImbcode',
  doSetAvatar: 'doSetAvatar',

  doListOrder: 'doListOrder',
  delOrderById: 'delOrderById',
  doReceipt: 'doReceipt',
  doListFinishOrder: 'doListFinishOrder',

  clearCart: 'clearCart',
  clearOrder: 'clearOrder',
  clearDashboard: 'clearDashboard',
  loadDistrictList: 'loadDistrictListloadDistrictList',
  doGetDistrictsByAddr: 'doGetDistrictsByAddr',

  doArticleDetail: 'doArticleDetail',
  readThisArticle: 'readThisArticle',
  doLike: 'doLike',
  saveSku2Sharer: 'saveSku2Sharer',
  doShare: 'doShare',
  doLoadMyShop: 'doLoadMyShop',
  doUnbindShop: 'doUnbindShop',
  getCustomService: 'getCustomService',
  getCouponList: 'getCouponList',
  collarCoupon: 'collarCoupon',
  checkMCode: 'checkMCode',
  resetAddress: 'resetAddress',
  doSelectDistrict: 'doSelectDistrict',

  doListSurrogate: 'doListSurrogate',
  doListSuritem: 'doListSuritem',
  doCreateSurrogate: 'doCreateSurrogate',
  doShareSku: 'doShareSku',

  activityDetail: 'activityDetail',
  activityMain: 'activityMain',
  relayActivity: 'relayActivity',
  checkAcCode: 'checkAcCode',
  winnerList: 'winnerList',
  prizeList: 'prizeList',
  collarActivityCode: 'collarActivityCode',
  collarKocCoupon: 'collarKocCoupon',
  redeemCode: 'redeemCode',

  guessYouLike: 'guessYouLike',
  goodsListBySaleType: 'goodsListBySaleType',
  bannerList: 'bannerList',
  checkedSkuIdxList: 'checkedSkuIdxList',
  getOrderDetail: 'getOrderDetail',
  doSelectFullAddress: 'doSelectFullAddress',
  autocomplate: 'autocomplate',
  sendDetail: 'sendDetail',
  doSkuListAll: 'doSkuListAll',
  sendCode: 'sendCode',
  register: 'register',
  countryMobilePrefix: 'countryMobilePrefix',
  updateKidInfo: 'updateKidInfo',
  bindMobile: 'bindMobile',
  login: 'login',
  kidList: 'kidList',
  addKid: 'addKid',
  delKid: 'delKid',
  getKidInfo: 'getKidInfo',

  bannerListByNotLogin: 'bannerListByNotLogin',
  skuTopicList: 'skuTopicList',
  skuTopicInfo: 'skuTopicInfo',
  wxPay: 'wxPay',
  shopInfo: 'shopInfo',
  feedback: 'feedback',
  orderTrace: 'orderTrace',
  flashSale: 'flashSale',
  sysTime: 'sysTime',
  resetSkuInfo: 'resetSkuInfo',
  resetFlashSaleActivity: 'resetFlashSaleActivity',
  maimengMoments: 'maimengMoments',
  saveMediaUrlList: 'saveMediaUrlList',
  saveVideoUrl: 'saveVideoUrl',
  pubMaimengMoments: 'pubMaimengMoments',
  uploadVideoFile: 'uploadVideoFile',
  delMaimengMoments: 'delMaimengMoments',
  checkedMaimengMomentInfo: 'checkedMaimengMomentInfo',
  editMaimengMoment: 'editMaimengMoment',
  fightGroupAll: 'fightGroupAll',
  fightGroup: 'fightGroup',
  myFightGroupList: 'myFightGroupList',
  resetFightGroupActivity: 'resetFightGroupActivity',
  myFightGroupItem: 'myFightGroupItem',
  setSkuPrice2fightGroup: 'setSkuPrice2fightGroup',
  resetSkuPrice: 'resetSkuPrice',
  promotionSaleActivity: 'promotionSaleActivity',
  setup: 'setup',
  lotteryActivityDetail: 'lotteryActivityDetail',
  lotteryActivityToDraw: 'lotteryActivityToDraw',
  lotteryActivityUserShare: 'lotteryActivityUserShare',
  lotteryActivityWinnerList: 'lotteryActivityWinnerList',
  homeEnter: 'homeEnter',
  skuDetailList: 'skuDetailList',
  topupMaimengMoments: 'topupMaimengMoments',
  aliOssStsToken: 'aliOssStsToken',
  maimengRegister: 'maimengRegister',
  customerServiceByAgentId: 'customerServiceByAgentId',
  dialogActivityList: 'dialogActivityList',
  joinInDialogActivity: 'joinInDialogActivity',
  bindMM: 'bindMM',
  findMMInfo: 'findMMInfo',
  unbindMobile: 'unbindMobile',
  recycleList: 'recycleList',
  recycleAddApply: 'recycleAddApply',
  recycleDetail: 'recycleDetail',
  recycleCouponList: 'recycleCouponList',
  recycleExchangeCoupon: 'recycleExchangeCoupon',
  bindShare: 'bindShare',
  promotionSaleActivityRecommendSkuList: 'promotionSaleActivityRecommendSkuList',
  skuListByColor: 'skuListByColor',
  penColorList: 'penColorList',
  newuserActivityInfo: 'newuserActivityInfo',
  newuserActivityCollarCoupon: 'newuserActivityCollarCoupon',
  buyScore: 'buyScore',
  scoreTaskList: 'scoreTaskList',
  activeTask: 'activeTask',
  joinActiveTask: 'joinActiveTask',
  scoreList: 'scoreList'
}
let methods = [SUCCESS.toLowerCase(), FAILURE.toLowerCase(), REQUEST.toLowerCase()]
const mutationNames = {}
/* for (let actionName in globalConst) {
  if (globalConst.hasOwnProperty(actionName)) {
    methods.forEach((method) => {
      if (!mutationNames[`${actionName}_${method}`]) {
        mutationNames[`${actionName}_${method}`] = `${actionName}_${method}`
      }
    })
  }
}*/

const pageSize = 10
// 满减券
export const COUPON_TYPE_DISCOUNT_FULL_CUT = 0
// 单品立减券
export const COUPON_TYPE_DISCOUNT_SINGLE = 1
// 立减券
export const COUPON_TYPE_DISCOUNT = 2
// 折扣券
export const COUPON_TYPE_DISCOUNT_RATE = 3
// 随机券
export const COUPON_TYPE_DISCOUNT_RANDOM = 4
// 包邮券
export const COUPON_TYPE_DISCOUNT_EXPRESS = 5
// 单品指定价格券
export const COUPON_TYPE_ASSIGN_PRICE_SINGLE = 6

// 1:可用，0:已使用，-1，失效,2:已使用并支付
export const COUPON_STATUS_USABLE = 1
export const COUPON_STATUS_USED = 0
export const COUPON_STATUS_USED_PAY = 2
export const COUPON_STATUS_EXPIRE = -1
// 优惠券使用模式  ，1使用模式，0 展示模式
export const COUPON_MODE_USE = 1
export const COUPON_MODE_SHOW = 0

// 优惠券缓存模式 0 不互斥， 1 部分互斥 2 全部互斥
export const COUPON_MUTEX_NONE = 0
export const COUPON_MUTEX_PART = 1
export const COUPON_MUTEX_ALL = 2

export const USER_TYPE_NEW_USER = 0
export const USER_TYPE_NORMAL_USER = 1
export const USER_TYPE_VIP_USER = 2
export const USER_TYPE_ALL_USER = 3
export const USER_TYPE_EXPAND_USER = 4

export const GOODS_SOURCE_PLACE = 0
export const GOODS_SOURCE_CATALOG = 1
export const GOODS_SOURCE_SEARCH = 2
export const GOODS_SOURCE_SHOP = 3
export const GOODS_SOURCE_COLOR = 4
export const GOODS_SOURCE_SCORE = 5

// 商品分类归属 0：普通商品， 1 点读笔， 2 玩具 ，3 图书 ，9点读图书
export const GOODS_TYPE_NONE = 0
export const GOODS_TYPE_I_PEN = 1
export const GOODS_TYPE_TOY = 2
export const GOODS_TYPE_BOOK = 3
export const GOODS_TYPE_TALK_BOOK = 9
export const goodsPlaces = [
  {id: GOODS_TYPE_NONE, name: '普通商品'},
  {id: GOODS_TYPE_I_PEN, name: '点读笔'},
  {id: GOODS_TYPE_TOY, name: '玩具'},
  {id: GOODS_TYPE_BOOK, name: '图书'},
  {id: GOODS_TYPE_TALK_BOOK, name: '点读图书'}
]

export const ORDER_STATUS_ALL = -1
export const ORDER_STATUS_UNPAID = 0
export const ORDER_STATUS_PAID = 1
export const ORDER_STATUS_WAIT_RECEIPT = 2
export const ORDER_STATUS_FINISH = 3
export const ORDER_STATUS_CANCEL = 4
export const ORDER_STATUS_REFUND = 5
export const ORDER_STATUS_FIGHT_GROUP_ING = 7
export const ORDER_STATUS_FIGHT_GROUP_EXPIRED = 8
export const expressCompanyNameMap = {
  'SF': '顺丰快递',
  'HTKY': '百世汇通',
  'STO': '申通快递',
  'UC': '优速快递',
  'ZTO': '中通快递',
  'YTO': '圆通快递',
  'YD': '韵达快递',
  'HHTT': '天天快递',
  'YZPY': '邮政快递',
}

export const expressStatus = {
  2: '在途中',
  3: '已签收',
  4: '问题件'
}

// flash
export const FLASH_SALE_STATUS_NO_START = 0
export const FLASH_SALE_STATUS_START = 1
export const FLASH_SALE_STATUS_EXPIRED = -1
// fightGroup
export const FIGHT_GROUP_STATUS_NO_START = 0
export const FIGHT_GROUP_STATUS_START = 1
export const FIGHT_GROUP_STATUS_EXPIRED = -1

export {
  globalConst,
  modalTitle,
  pageSize,
  SUCCESS,
  FAILURE,
  REQUEST,
  ERROR_UNAUTHORIZED,
  mutationNames
}
