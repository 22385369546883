<template>
  <section class="pay-item">
    <div class='pay-item-sku'>
      <div class='sku-media'><img :src="getSkuMediaUrl(sku.skuId)"
                                  alt/></div>
      <div class='pay-item-inner'>
        <div class="item-title">{{ sku.name }}</div>
        <div v-if='materialName'>{{ materialName }}</div>
        <div class='item-after' v-if="useScore">
          <div class='sku-quantity'>x {{ sku.quality }}</div>
        </div>
        <div class='item-after' v-else>
          <div class='sku-price'>{{sku.skuprice}}</div>
          <div class='sku-quantity'>x {{ sku.quality }}</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang=ts setup>
import {CartSku} from "@/store/modules/cart";
import {useGlobal} from "@/hook/globalHook";
import {getSkuMediaUrl} from "@/hook/skuHook";

const props = defineProps({
  sku: {
    type: CartSku
  },
  useScore: {
    type: Boolean,
    default: false
  }
})
const {jump} = useGlobal()
const materialName = props.sku?.arrMater ? props.sku.arrMater[props.sku.material] : ''
console.log('测试----->',materialName)
</script>
<style lang="scss" scoped type="text/css">
@import "comfirmOrderItems.scss";
</style>
