import {createStore, Store, useStore as baseUssStore} from "vuex";
import type {InjectionKey} from "vue";
import * as cart from './modules/cart'
import * as address from './modules/address'

export interface State {
  openId: string
  token: string
  debug: boolean
  sku: any
  profile: any
  shopId: number
  wechat: any
}

export const key: InjectionKey<Store<State>> = Symbol()
const token = import.meta.env.public_token
console.log('token--->', token)
export const store = createStore<State>({
  state: {
    openId: '',
    token: token,
    debug: false,
    sku: {},
    profile: null,
    shopId: 0,
    wechat: ''
  },
  mutations: {
    setOpenId(state, data) {
      state.openId = data
    },
    setToken(state, token) {
      console.log('settoken', state, token)
      state.token = token
    },
    setSkuDetail(state, data) {
      let {skuId} = data
      state.sku[`detail_${skuId}`] = data
    },
    setWechat(state, config) {
      state.wechat = config
    },
    setShopId(state, shopId) {
      state.shopId = shopId
    }
  },
  modules: {cart, address}
})

export function useStore() {
  return baseUssStore(key)
}
