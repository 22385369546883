<template>
  <x-page no-toolbar class='login-page'>
    <template v-slot:navbar>
      <van-nav-bar title="登录"></van-nav-bar>
    </template>
    <!--    <header class='tab1-header'>登录麦芽平台开启阅读之旅</header>-->
    <form data-v-56e4d35e="" class="list media-list no-hairlines no-hairlines-between inline-labels">
      <ul>
        <li data-v-56e4d35e="" class="">
          <div class="item-content item-input">
            <div class="item-media">
              <div data-v-56e4d35e=""><img data-v-56e4d35e="" src="@/assets/icon/icon_mobile.png" alt=""
                                           class="join-icon"></div>
            </div>
            <div class="item-inner">
              <div class="item-title item-label">
                <div data-v-56e4d35e="" class="phone-prefix">
                  +86
                </div>
              </div>
              <div class="item-input-wrap"><input type="number" v-model="mobile" placeholder="请输入电话号码" class=""></div>
            </div>
          </div>
        </li>
        <li data-v-56e4d35e="" class="input-code">
          <div class="item-content item-input item-input-with-info">
            <div class="item-media">
              <div data-v-56e4d35e=""><img data-v-56e4d35e="" src="" alt="" class="join-icon" style="opacity: 0;"></div>
            </div>
            <div class="item-inner">
              <div class="item-input-wrap"><input type="number" v-model="code" placeholder="请输入验证码" max="6"
                                                  maxlength="6" class=""
                                                  style="width: 50%;">
                <div class="item-input-info">
                  <a href="#" onclick="return false;" v-if="!duanxinClock.hasSendCode && !duanxinClock.awaiting"
                     @click="sendCode(0)">获取验证码</a>
                  <a href="#" v-else-if="duanxinClock.hasSendCode && !duanxinClock.awaiting"
                     @click="sendCode(0)">重新获取验证码</a>
                  <span v-else-if="duanxinClock.awaiting">{{ duanxinClock.timer }}s重新获取</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </form>
    <div class='btn btn-next' @click="submit">登录</div>
  </x-page>
</template>

<script lang=ts setup>
import {globalConst as native} from '@/utils/const'
import {reactive, toRefs, unref} from "vue";
import {showToast} from "vant";
import {Api} from "@/api/api";
import {useRoute} from "vue-router";
import {useStore} from "@/store";
import {useGlobal} from "@/hook/globalHook";
/*eslint no-magic-numbers: 0*/
const countDown = 60 * 2

class CustomClock {
  timer: number
  awaiting: boolean
  hasSendCode: boolean
  clock: undefined | number

  constructor() {
    this.timer = countDown
    this.awaiting = false
    this.hasSendCode = false
    this.clock = undefined
  }
}

const route = useRoute()
const store = useStore()
const {jump} = useGlobal()
const data = reactive({
  mobile: '',
  code: '',
  mobilePrefix: '86',
  duanxin: 1,
  duanxinClock: new CustomClock()
})
const {mobile, code, mobilePrefix, duanxin, duanxinClock} = toRefs(data)
const openId = route.query.openId
const hashUrl = route.query.hashUrl || '/home'

function validate() {
  if (data.mobile.length <= 0) {
    return '手机号不可以为空'
  }
  return null
}

async function submit() {
  let msg = validate()
  if (msg) {
    showToast(msg)
    return
  }
  Api.login({
    mobile: String(data.mobile),
    code: data.code,
    prefix: data.mobilePrefix,
    openId: openId
  }).then((token) => {
    showToast('登录成功')
    store.commit('setToken', token)
    jump(hashUrl)

  }).catch((err) => {
    showToast(err)
  })
}

function sendCode() {
  let msg = validate()
  if (msg) {
    showToast(msg)
    return
  }
  Api.sendCode({
    mobile: String(data.mobile),
    prefix: data.mobilePrefix,
    openId: openId
  }).then((res) => {
    data.duanxinClock.hasSendCode = true
    data.duanxinClock.awaiting = true
    if (data.duanxinClock.clock) {
      return
    }
    data.duanxinClock.clock = setInterval(() => {
      data.duanxinClock.timer--
      if (data.duanxinClock.timer <= 0) {
        clearInterval(data.duanxinClock.clock)
        data.duanxinClock.clock = undefined
        data.duanxinClock.awaiting = false
        data.duanxinClock.timer = countDown
      }
    }, 1000)
  }).catch((error) => {
    console.error(error)
    data.duanxinClock.awaiting = false
    data.duanxinClock.timer = countDown
    showToast(error)
    return
  })
}
</script>

<style lang="scss" scoped type="text/css">
@import "./login";
</style>
