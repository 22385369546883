import {computed, createApp, unref} from 'vue';
import './utils/flexible'
import App from './App.vue';
import {router} from "./router/router";
import 'vant/lib/index.css'
import {key, store} from "./store";
import {saveStore} from "./utils/utils";
import XPage from './components/xui/xpage/XPage.vue';
import {Api} from "./api/api";

let user_store = sessionStorage.getItem('user_store')
user_store && store.replaceState(Object.assign(store.state, JSON.parse(user_store)))

let cart = localStorage.getItem('cart')
if (cart) {
  store.state.cart.cart = JSON.parse(cart)
}
setTimeout(() => {
  setWechatConfig()
}, 1500)
const app = createApp(App)
app.use(router)
app.use(store, key)
app.mount('#app');

app.component('XPage', XPage)

window.onbeforeunload = function () {
  saveStore(store.state)
}
window.addEventListener('pagehide', function () {
  saveStore(store.state)
}, false)
window.addEventListener('resize', function () {
  if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
    window.setTimeout(function () {
      document.activeElement.scrollIntoViewIfNeeded()
      // document.activeElement.scrollIntoView(false)
    }, 0)
  }
})

window.addEventListener('focusout', function () {
  window.scrollTo(0, 0)
})

async function setWechatConfig() {
  let config = await Api.getWechatConfig()
  wx.config(config)
}
