<template>
  <div>jump</div>
</template>

<script lang=ts setup>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "@/store";

const store = useStore()
const route = useRoute()
const router = useRouter()
const token = route.query.token
const hashUrl = route.query.hasUrl || '/home'
const openId = route.query.openId
const shopId = route.query.shopId || 88
store.commit('setShopId', shopId)

if (token && token.length > 0) {
  store.commit('setToken', token)
  router.replace(hashUrl)
} else {
  router.replace(`/login?openId=${openId}&hasUrl=${hashUrl}`)
}
</script>

<style lang="scss" scoped type="text/css">

</style>
