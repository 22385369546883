<template>
  <x-page>
    <template v-slot:navbar>
      <van-nav-bar title="购物车"></van-nav-bar>
    </template>
    <section class="cart" v-if="shoppingCart.length>0">
      <ul class='ul-cart'>
        <li v-for="(row, index) in shoppingCart" :key="row.key">
          <div class="my-checkbox">
            <input type="checkbox"
                   v-model="checkModel"
                   :value="row.key"
                   name="cart_items"
                   :disabled="row.curStock<=0"
                   :id="row.key"/>
            <label :for="row.key"
                   :class="{checked: checkModel.indexOf(row.key) > -1}"></label>
          </div>
          <div class='ul-cart-right'>
            <div class='ul-cart-right-wrap'>
              <div class='sku-media'><img @click="jump(`/sku?skuId=${row.skuId}`)"
                                          :src="getSkuMediaUrl(row.skuId)"
                                          alt/></div>
              <div class="goods-info">
                <div class='goods-info-desc'>
                  <div><strong class='sell-out'
                               v-if="row.curStock<=0">该商品库存不足，无法下单</strong>
                  </div>
                  <div><strong class='sell-out'
                               v-if="row.detail && row.detail.error">{{ row.detail.error }}</strong>
                  </div>
                  <div @click="jump(`/sku?skuId=${row.skuId}`)" class='sku-name'>{{ row.name }}
                  </div>
                  <div><span
                      class="attr"
                      v-if="row.arrMater && row.arrMater[row.material]"
                  >{{ row.arrMater[row.material] }}</span></div>
                </div>
                <div class='sku-price'>
                  <div class='new-price'>{{ row.skuprice }}</div>
                  <div class="counter-wrapper">
                    <van-stepper
                        :name="index"
                        :min="0"
                        :max="row.curStock"
                        v-model="row.quality"
                        @change="changeCounter"
                    ></van-stepper>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </li>
      </ul>
    </section>
    <section class="empty-cart" v-else>
      <p>购物车空空如也</p>
    </section>

    <div slot='fixed'>
      <div class="total-price" v-if="cart.skus && cart.skus.length>0">
        <div class="my-checkbox">
          <input type="checkbox" v-model="checkAll" :id="checkAllId"/>
          <label :for="checkAllId" :class="{checked: checkAll}">已选({{ checkModel.length }})</label>
        </div>
        <div class="btn" :class="{'active':checkModel.length>0}" @click="toPay">去结算</div>
        <div class="total">¥<strong>{{ totalPrice }}</strong></div>
      </div>
    </div>
  </x-page>
</template>

<script lang=ts setup>
import {useRouter} from "vue-router";
import {useStore} from "@/store";
import {computed, onMounted, ref, unref, watch} from "vue";
import {Api} from "@/api/api";
import {CartSku} from "@/store/modules/cart";
import {useGlobal, getMediaUrl, toFixed} from "@/hook/globalHook";
import {getSkuMediaUrl} from "@/hook/skuHook";
import {showToast} from "vant";

const checkAllId = 'checkAll'
const {jump, shopId} = useGlobal()
const store = useStore()
const router = useRouter()
const shoppingCart = ref<CartSku[]>([])
const cart = computed(() => {
  let nowSkus = store.state.cart.cart.skus.filter((sku) => parseInt(sku.shopId) === parseInt(unref(shopId)))
  return {skus: nowSkus}
})

const checkModel = ref<string[]>([])
const checkAll = ref(false)
watch(checkAll, (nowV, oldV) => {
  if (oldV !== nowV) {
    if (nowV) {
      checkModel.value = []
      for (let i = 0; i < shoppingCart.value.length; i++) {
        let sku = shoppingCart.value[i]
        checkModel.value.push(sku.key)
      }
    } else {
      checkModel.value = []
    }
  }
})
watch(checkModel, (nowV, oldV) => {
  if (oldV !== nowV) {
    if (nowV.length === cart.value.skus.length) {
      checkAll.value = true
    } else if (checkModel.value.length === 0) {
      checkAll.value = false
    }
  }
})

async function checkCart() {
  if (cart.value.skus.length > 0) {
    checkModel.value = []
    let skuMap = {}
    for (let i = cart.value.skus.length - 1; i >= 0; i--) {
      try {
        let {skuId, material, quality} = cart.value.skus[i]
        let skuInfo = await Api.skuDetail({skuId, shopId: unref(shopId)})
        skuMap[skuId] = skuInfo.detail
        store.commit('setSkuDetail', skuInfo)

        let cartSku = new CartSku(skuId, material, quality)
        cartSku.init(skuInfo.detail)
        /*&& cartSku.detail.status === 1*/
        if (cartSku.curStock > 0) {
          checkModel.value.push(cartSku.key)
        }
        shoppingCart.value.push(cartSku)
      } catch (e) {
        cart.value.skus.splice(i, 1)
        localStorage.setItem('cart', JSON.stringify(cart.value))
      }
    }
  }
}

function changeCounter(value, detail) {
  console.log('e', value, detail)
  let {name: idx} = detail
  let baseData = shoppingCart.value[idx]
  let {skuId, material, key} = baseData
  if (value >= baseData.curStock) {
    showToast('商品最大购买数量~')
  }
  store.commit('updateToCart', {
    skuId,
    material,
    quality: parseInt(value, 10),
    shopId: unref(shopId),
    cb: () => {
      let idx = checkModel.value.indexOf(key)
      if (idx !== -1) {
        shoppingCart.value.splice(idx, 1)
        checkModel.value.splice(idx, 1)
        showToast('该商品已删除~')
      }
    }
  })
}

async function initData() {
  // let promiseProfile = this.loadProfile()
  await checkCart()
}

onMounted(async () => {
  await initData()
})

const totalPrice = computed(() => {
  if (checkModel.value.length > 0) {
    let total = 0
    for (let i = 0; i < shoppingCart.value.length; i++) {
      let row = shoppingCart.value[i]
      if (!checkModel.value.includes(row.key)) {
        continue
      }
      total += (row.skuprice + (row.detail.matPrice[row.material] || 0)) * row.quality
    }
    return toFixed(total)
  } else {
    return 0
  }
})

function toPay() {
  if (checkModel.value.length === 0) {
    showToast('请选择需要购买的商品')
    return
  }
  let checkedSku: any[] = []
  for (let i = 0; i < shoppingCart.value.length; i++) {
    let sku = shoppingCart.value[i]
    let {skuId, material, key} = sku
    if (checkModel.value.indexOf(key) !== -1) {
      checkedSku.push({
        skuId,
        material: sku.material,
        key: sku.key,
        quality: sku.quality
      })
    }
  }
  store.commit('checkedSkuIdxList', checkedSku)
  // if (!this.profile.bindMobile) {
  //   this.$f7router.navigate('/joinIn?backLink=/comfirmOrder')
  //   return
  // }
  sessionStorage.removeItem('orderNo')
  router.push('/confirmOrder')
}

</script>

<style lang="scss" scoped type="text/css">
@import "./cart";
</style>
