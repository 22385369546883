<template>
  <x-page>
    <template v-slot:navbar>
      <van-nav-bar title="地址列表" left-text="返回" @click="pageBack"></van-nav-bar>
    </template>
    <section class="address-info">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadAddressData"
      >
        <div class="container" v-for="(address,index) in addressList" :key="index"
             @click="editAddress(address.id,address)">
          <div class="name-wrapper">
            <p class="name">{{ address.contact }}</p>
          </div>
          <div class="moblie-wrapper">
            <div class="mobile">
              {{ address.mobile }}
              <span class="default" v-if="address.default===1">默认</span>
            </div>
            <p class="address">{{ address.fullAddress }}</p>
          </div>
        </div>
      </van-list>
    </section>

    <template v-slot:toolbar>
      <div class="add-address">
        <my-button @click="editAddress(-1)" class='btn-add-address'>+ 新增地址</my-button>
      </div>
    </template>
  </x-page>
</template>

<script lang=ts setup>
import MyButton from '@/components/MyButton/MyButton'
import {globalConst as native, pageSize as size} from '@/utils/const'
import {onMounted, reactive, toRefs} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useGlobal} from "@/hook/globalHook";
import {useStore} from "@/store";
import {Api} from "@/api/api";
import {useLoadDataHook} from "@/hook/loadDataHook";

const route = useRoute()
const store = useStore()
const router = useRouter()
const {pageBack} = useGlobal()
const data = reactive({
  hasAddress: true,
  index: 0,
  addressList: [],
  listLoading: false,
  listError: false,
  hasProxy: 0
})
let {addressList, index} = toRefs(data)
const {loading, finished, refreshing, error, loadData} = useLoadDataHook()
data.hasProxy = route.query.hasProxy
onMounted(() => {
  let refreshAddressList = sessionStorage.getItem('refreshAddressList')
  if (parseInt(refreshAddressList) === 1) {
    sessionStorage.removeItem('refreshAddressList')
  }
})

function loadAddressData() {
  loadData(() => {
    return Api.addressList({
      pageSize: size,
      index: data.index
    }).then((res) => {
      if (Array.isArray(res) && res.length > 0) {
        data.addressList = data.addressList.concat(res)
        data.index++
      } else {
      }
      return res
    })
  })
}

function editAddress(addressId, addressInfo) {
  if (addressId !== -1) {
    store.commit('setEditAddress', addressInfo)
  }
  router.push(`/address/add/${addressId}`)
}
</script>

<style lang="scss" scoped type="text/css">
@import "./address";
</style>
