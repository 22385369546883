<template>
<!--  <router-link to="/home">Go to Home</router-link>-->
<!--  <router-link to="/sku">Go to Sku</router-link>-->
<!--  <router-link to="/jump">Go to jump</router-link>-->
<!--  <router-link to="/cart">Go to cart</router-link>-->
<!--  <div @click="jump('/cart')">test to cart</div>-->
<!--  <div @click="jump('/confirmOrder')">test to confirmOrder</div>-->
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>

<script lang=ts setup>
import {useRouter} from "vue-router";

const router = useRouter()

function jump(path) {
  console.log('jump--->',path)
  router.push(path)
}
</script>

<style lang="scss">
@import "./app.scss";
</style>
