<template>
  <x-page>
    <section class="header" v-if='profile'>
<!--      <img :src="profile.avatar" class='avatar' alt/>-->
      <div class='h-media'></div>
      <div class='h-context'>
        <div class='h-title'>{{ profile.realname }}</div>
        <div class='bind-mobile'>{{ profile.umobile }}</div>
      </div>
    </section>
    <section class='my-toolbar order-modules'>
      <header>
        <div>我的订单</div>
        <div @click="toOrder(0)">全部订单</div>
      </header>
      <div class='my-toolbar-inner'>
        <a @click="toOrder(1)">
          <i class='icon icon-unpaid'></i>
          <span>待支付</span>
        </a>
        <a @click="toOrder(2)">
          <i class='icon icon-paid'></i>
          <span>待发货</span>
        </a>
        <a @click="toOrder(3)">
          <i class='icon icon-wait-receipt'></i>
          <span>待收货</span>
        </a>
      </div>
    </section>
    <section class='my-toolbar'>
      <header>
        <div>个人中心</div>
      </header>
      <div class='my-toolbar-inner  user-modules'>
        <!--      <a @click="jumpCS()">-->
        <!--        <i class='icon icon-cs'></i>-->
        <!--        <span>联系客服</span>-->
        <!--      </a>-->
        <a @click="jump('/address/list')">
          <i class='icon icon-address'></i>
          <span>地址管理</span>
        </a>
      </div>
    </section>
  </x-page>
</template>

<script lang=ts setup>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "@/store";
import {computed, onMounted, ref, watch, unref, onActivated} from "vue";
import {Api} from "@/api/api";
import {useGlobal, getMediaUrl, toFixed} from "@/hook/globalHook";
import {getSkuMediaUrl} from "@/hook/skuHook";
import {showToast} from "vant";
import PayItem from '@/components/payItem/PayItem.vue';
import {CartSku} from "@/store/modules/cart";
import {isEmptyObject} from "@/utils/utils";
import {
  globalConst as native,
  ORDER_STATUS_UNPAID,
  ORDER_STATUS_ALL,
  ORDER_STATUS_PAID,
  ORDER_STATUS_WAIT_RECEIPT
} from "@/utils/const";

const {jump, pageBack} = useGlobal()
const store = useStore()
const router = useRouter()
const route = useRoute()

const profile = computed(() => store.state.profile)

onMounted(() => {
  Api.getUserProfile().then((data) => {
    store.state.profile = data
  }).catch((err) => {
    showToast(err)
  })
})

function toOrder(idx: number) {
  router.push(`/order?idx=${idx}`)
}

function unbindMobile() {
  showToast('暂无解绑功能~')
}


</script>

<style lang="scss" scoped type="text/css">
@import "./my";
</style>
