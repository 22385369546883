<template>
  <div>
    <van-nav-bar :title="title" left-text="返回" @click="pageBack"></van-nav-bar>
    <van-address-edit
        :area-list="areaList"
        :address-info="addressInfo"
        :show-delete="showDel"
        show-set-default
        :show-search-result="false"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
        @change-detail="onChangeDetail"
    />
  </div>
</template>

<script lang=ts setup>
import {computed, ref, unref} from "vue";
import {showToast} from "vant";
import {areaList} from "@vant/area-data";
import {useGlobal} from "@/hook/globalHook";
import {useRoute} from "vue-router";
import {AddressEditInfo} from "vant";
import {Api} from "@/api/api";
import {useStore} from "@/store";

const route = useRoute()
const store = useStore()
const {pageBack} = useGlobal()
const addressId = parseInt(route.params.addressId as string, 10) || -1
const title = ref(addressId === -1 ? '新增地址' : '编辑地址')

const showDel = ref(false)
const addressInfo = ref<AddressEditInfo>({
  tel: '',
  name: '',
  city: '',
  county: '',
  country: '',
  province: '',
  areaCode: '',
  isDefault: false,
  addressDetail: '',
})
if (addressId !== -1) {
  showDel.value = true
  const editAddressInfo = computed(() => store.state.address.editAddress)
  let _addressInfo = unref(editAddressInfo)
  addressInfo.value = {
    tel: _addressInfo.mobile,
    name: _addressInfo.contact,
    city: _addressInfo.city,
    county: _addressInfo.district,
    country: '',
    province: _addressInfo.province,
    areaCode: '',
    isDefault: _addressInfo.default === 1,
    addressDetail: _addressInfo.address,
  }
}

const onSave = (address) => {
  let {
    tel,
    name,
    city,
    county,
    country,
    province,
    areaCode,
    isDefault,
    addressDetail
  } = address
  console.log('what>>>', address)
  if (addressId !== -1) {
    Api.addressEdit({
      addressId: addressId,
      detail: addressDetail,
      contact: name,
      mobile: tel,
      default: isDefault ? 1 : 0,
      province,
      city,
      district: county,
    }).then(() => {
      sessionStorage.setItem('refreshAddressList', '1')
      showToast('编辑地址成功~')
      setTimeout(() => {
        pageBack()
      }, 1500)
    }).catch((err) => {
      console.error('err', err)
      showToast('编辑地址失败')
    })
  } else {
    Api.addressAdd({
      detail: addressDetail,
      contact: name,
      mobile: tel,
      default: isDefault ? 1 : 0,
      province,
      city,
      district: county,
    }).then(() => {
      sessionStorage.setItem('refreshAddressList', '1')
      showToast('新增地址成功~')
      setTimeout(() => {
        pageBack()
      }, 1500)
    }).catch((err) => {
      console.error('err', err)
      showToast('新增地址失败')
    })
  }
}
const onDelete = () => showToast('delete');
const onChangeDetail = (val => {
})
</script>

<style lang="scss" scoped type="text/css">

</style>
