let state = {
  cart: {
    skus: []
  },
  quickBuyTarget: {},
  checkedSkuIdxList: []
}
let actions = {}

export class CartSku {
  material: string;
  skuId: number;
  quality: number
  stockList: number[]
  curStock: number
  name: string
  arrMater: string[]
  skuprice: number
  detail: any
  matPrice: number[]
  key: string

  constructor(skuId, material, quality) {
    this.skuId = skuId
    this.material = material
    this.quality = quality
    this.stockList = []
    this.curStock = 0
    this.name = ''
    this.arrMater = []
    this.skuprice = 0
    this.detail = {}
    this.matPrice = [0]
    this.key = ''
  }

  init(skuDetail) {
    this.detail = skuDetail
    let stockList = skuDetail.stock.split(',')
    let stock = stockList[this.material] ? parseInt(stockList[this.material], 10) : 0
    this.stockList = stockList
    this.curStock = stock
    this.name = skuDetail.name
    this.arrMater = skuDetail.material.split(',')
    this.detail = skuDetail
    this.key = this.skuId + '_' + this.material
    this.matPrice = skuDetail.matPrice
    let price = 0
    this.skuprice = skuDetail.price
  }
}

function changeCart(cart, skuId, quality, material, model = 'add', psId, shopId, cb) {
  let insert = true
  if (quality <= 0) {
    cart.skus = cart.skus.filter((sku) => !(parseInt(sku.skuId) === parseInt(skuId) && parseInt(sku.material) === parseInt(material) && parseInt(sku.shopId) === shopId))
    localStorage.setItem('cart', JSON.stringify(cart))
    cb && cb()
    return
  }
  cart.skus.map((sku) => {
    if (sku.skuId === skuId && sku.material === material) {
      if (model === 'append') {
        sku.quality += quality
      } else {
        sku.quality = quality
      }
      insert = false
    }
    return sku
  })
  if (insert) {
    cart.skus.push({
      skuId,
      material,
      quality,
      psId,
      shopId
    })
  }
  localStorage.setItem('cart', JSON.stringify(cart))
}

let mutations = {
  clearCart(state) {
    state.checkedSkuIdxList.forEach((row) => {
      let {skuId, material} = row
      changeCart(state.cart, skuId, 0, material)
    })
    state.checkedSkuIdxList = []
  },
  addToCart({cart}, {skuId, material, quality, model, psId, shopId, cb}) {
    changeCart(cart, skuId, quality, material, model, psId, shopId, cb)
  },
  updateToCart({cart}, {skuId, material, quality, model, psId, shopId, cb}) {
    changeCart(cart, skuId, quality, material, model, psId, shopId, cb)
  },
  quickBuyTarget(state, target) {
    state.quickBuyTarget = target
  },
  checkedSkuIdxList(state, checkedSkuIdxList) {
    state.checkedSkuIdxList = checkedSkuIdxList
  }
}

export {
  state,
  getters,
  actions,
  mutations
}
