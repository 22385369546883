import {ref} from "vue";
import {pageSize} from "@/utils/const";
import {showToast} from "vant";

export function useLoadDataHook() {
  const loading = ref(false)
  const finished = ref(false)
  const refreshing = ref(false)
  const error = ref(null)

  async function loadData(fn) {
    try {
      loading.value = false
      let data = await fn()
      loading.value = false
      if (Array.isArray(data) && data.length > 0) {
        finished.value = false
      } else {
        finished.value = true
      }
      if (data.length < pageSize) {
        finished.value = true
      }
      return data
    } catch (e) {
      loading.value = false
      console.error('e',e)
      showToast(e)
      error.value = e
    }
  }


  return {
    loading,
    finished,
    refreshing,
    error,
    loadData
  }
}
