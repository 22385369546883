<template>
  <section class="goods-item" @click="jump(`/sku?skuId=${goods.skuId}`)">
    <!--:src="mediaUrl(goods)"-->
    <img class='lazy sku-media' :src="getSkuMediaUrl(goods.skuId)" ref='lazyMedia' alt/>
    <div class="goods-detail">
      <p class="goods-title">{{ goods.name }}</p>
      <div class='goods-desc'>
        <div>
                    <span class="current-price">
                      ¥<strong>{{ goods.defprice || goods.price }}</strong>
                    </span>
          <strike class='color-gray'>¥{{ goods.originalPrice }}</strike>
        </div>
        <div class='tab-link-active'>
          <div class="icon-add-cart"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang=ts setup>
import {useGlobal} from "@/hook/globalHook";
import {getSkuMediaUrl} from "@/hook/skuHook";

const props = defineProps({
  goods: Object
})
const {jump} = useGlobal();

</script>

<style lang="scss" scoped type="text/css">
@import "./goodsItem.scss";
</style>
