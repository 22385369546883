<template>
  <div class='order-list-wrap'>
    <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadData"
    >
      <section class="order-list" v-for="(order,index) in orderList" :key="index" @click="jumpOrderDetail(order)">
        <div class="order-list-title">
                <span>订单号 {{ order.orderNo }}<strong v-if="order.surrogate === 1">(囤货订单)</strong><strong
                    v-if="order.surrogate === 2">(退单)</strong></span>
          <span class='order-status'>
                    <template v-if='order.status===ORDER_STATUS_UNPAID'>
                        <span v-if="order.createTime+30*60*1000>=nowTime">待付款</span>
                        <span v-else>订单超时已取消</span>
                    </template>
                    <template v-else-if='order.status===ORDER_STATUS_PAID'>待发货</template>
                    <template v-else-if='order.status===ORDER_STATUS_WAIT_RECEIPT'>待收货</template>
                    <template v-else-if='order.status===ORDER_STATUS_FINISH'>已完成</template>
                    <template v-else-if='order.status===ORDER_STATUS_CANCEL'>已取消</template>
                    <template v-else-if='order.status===ORDER_STATUS_REFUND'>已取消</template>
                </span>
        </div>
        <div class='order-list-context'>
          <order-item v-for="(sku,idx) in order.skus"
                      :sku="sku"
                      :sku-id="sku.skuId"
                      :key="idx"></order-item>
          <div class="total-cost" v-if='order.surrogate !== 2'>
            共<strong>{{ order.skus ? order.skus.length : 0 }}</strong>件商品&nbsp;&nbsp;
            <template v-if="order.actualprice">应付总额：<strong class='unit'>¥</strong><strong
                class='price'>{{ order.actualprice  }}</strong></template>
          </div>
          <div class="total-cost" v-else>
            退款金额:¥<strong class='price'>{{ -order.price }}</strong>
          </div>
        </div>
        <div class="order-list-footer">
          <my-button
              v-if="( order.status === ORDER_STATUS_UNPAID) && order.createTime+ 30*60*1000 >= nowTime"
              active
              @click="onPay(order)">
            立即付款
          </my-button>
          <my-button
              v-if=" (order.status === ORDER_STATUS_WAIT_RECEIPT) || (order.surrogate===1 && (order.status===ORDER_STATUS_PAID))"
              active
              @click="onReceipt(order)">
            确认收货
          </my-button>
          <!--          <my-button-->
          <!--              v-if="order.logisticNo"-->
          <!--              active-->
          <!--              @click="onOrderTrace(order)">-->
          <!--            查看物流信息-->
          <!--          </my-button>-->
        </div>
      </section>
      <div slot="no-results" v-if="orderList.length===0">
        <section class="empty-order">
          <div class="tips">
            <img src="../../assets/empty-order.png" alt/>
            <p>暂无订单记录</p>
            <p>你的购物记录会出现在这里</p>
          </div>
        </section>
      </div>
    </van-list>

  </div>
</template>
<script lang=ts setup>
import MyButton from '@/components/MyButton/MyButton.vue'
import OrderItem from './OrderItem.vue'
import moment from 'moment'
import {
  globalConst as native, pageSize, ORDER_STATUS_UNPAID,
  ORDER_STATUS_WAIT_RECEIPT,
  ORDER_STATUS_ALL,
  ORDER_STATUS_PAID,
  ORDER_STATUS_FINISH,
  ORDER_STATUS_CANCEL,
  ORDER_STATUS_REFUND,
  ORDER_STATUS_FIGHT_GROUP_ING,
  ORDER_STATUS_FIGHT_GROUP_EXPIRED
} from '@/utils/const'
import {computed, ref, unref} from "vue";
import {useRouter} from "vue-router";
import {Api} from "@/api/api";
import {useOrder} from "@/hook/orderHook";
import {useGlobal} from "@/hook/globalHook";

const props = defineProps({
  orderstatus: Number
})
const {shopId} = useGlobal()
const orderList = ref([])
const pageIndex = ref(0)
const nowTime = ref(new Date())
const router = useRouter()
const {onPay, onReceipt} = useOrder()

const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);


function jumpOrderDetail(order) {
  router.push(`/order/detail/${order.orderNo}`)
}

function totalPrice(order) {
  let {actualprice} = order
  let total = actualprice
  return total > 0 ? total : 0
}

function loadData() {
  loading.value = true
  Api.orderList({
    index: pageIndex.value,
    pageSize,
    status: props.orderstatus,
    shopId: unref(shopId)
  }).then((data) => {
    if (refreshing.value) {
      orderList.value = [];
      refreshing.value = false;
    }
    let result = data
    if (Array.isArray(result) && result.length > 0) {
      orderList.value = orderList.value.concat(result)
      if (nowTime.value === null) {
        nowTime.value = orderList.value[0].nowTime
        let _orderClock = setInterval(() => {
          nowTime.value += 1000
        }, 1000)
      }
      //$state.loaded()
      pageIndex.value += 1
      loading.value = false
    } else {
      loading.value = false
      finished.value = true
      //$state.complete()
    }
    if (result.length < pageSize) {
      // $state.complete()
      finished.value = true
    }
  }).catch((err) => {
    loading.value = false
  })
}

function onRefresh() {
  orderList.value = []
  finished.value = false
  loading.value = true
  pageIndex.value = 0
  loadData()
}
</script>
<style lang="scss" scoped type="text/css">
@import "./orderList.scss";
</style>
