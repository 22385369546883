<template>
  <x-page class='home-page'>
    <template v-slot:navbar>
      <van-nav-bar title="首页"></van-nav-bar>
    </template>
    <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadData"
    >
      <div class='goods-list-wrap'>
        <section class="goods-list">
          <goods-item v-for="(goods,index) in skuList" :goods="goods"></goods-item>
        </section>
      </div>
    </van-list>

  </x-page>
</template>

<script lang=ts setup>
import GoodsItem from '@/components/goodsList/GoodsItem.vue'
import {computed, ref, unref} from "vue";
import {useLoadDataHook} from '@/hook/loadDataHook'
import {Api} from "@/api/api";
import {useStore} from "@/store";

const store = useStore()
const shopId = computed(() => store.state.shopId)
const {loading, finished, loadData: _loadData} = useLoadDataHook()
const index = ref(0)
const skuList = ref([])

function loadData() {
  _loadData(function () {
    return Api.skuList({
      shopId: unref(shopId),
      index: unref(index)
    }).then((data) => {
      index.value++
      skuList.value = skuList.value.concat(data)
      return data
    })
  })
}
</script>

<style lang="scss" scoped type="text/css">
@import "./home";
</style>
