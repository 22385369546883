import {useStore} from "@/store";
import {showToast} from "vant";
import {Api} from "@/api/api";
import {ORDER_STATUS_FINISH} from "@/utils/const";
import {globalConst as native} from "@/utils/const";

export function useOrder() {

  const store = useStore()

  function onPay(order) {
    let {orderNo} = order
    store.commit(native.clearOrder)
    if (typeof WeixinJSBridge === 'undefined') {
      showToast('请在微信浏览器支付')
      return
    }
    Api.wxPay({orderNo: orderNo}).then((data) => {
      console.log('微信支付返回的参数：', data)
      const wxpayParams = data.pay
      const {timeStamp, nonceStr, signType, paySign} = wxpayParams
      wx.chooseWXPay({
        timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr, // 支付签名随机串，不长于 32 位
        package: wxpayParams.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign, // 支付签名
        success: (res) => {
          // 支付成功后的回调函数
          order.status = 1
        },
        fail: (error) => {
          console.error('error', error)
          showToast(error.errMsg || error || '系统异常')
        }
      })
    })
  }

  function onReceipt(order) {
    Api.orderReceipt({
      orderNo: order.orderNo
    }).then(() => {
      order.status = ORDER_STATUS_FINISH
      showToast('收货完成~')
    })
  }


  function onOrderTrace(order) {

  }

  return {
    onPay,
    onReceipt,
    onOrderTrace
  }
}
