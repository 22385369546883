import {useRouter} from "vue-router";
import {useStore} from "@/store";
import {computed, unref} from "vue";

export function getMediaUrl() {
  return 'https://haomai-files.oss-cn-shenzhen.aliyuncs.com/'
}

export function toFixed(value: number, num = 2) {
  if (!value) {
    return value
  }
  let number = Number(value).toFixed(num)
  let [natural, decimal] = number.split('.')
  return decimal === '00' ? natural : number
}

export function useGlobal() {
  const router = useRouter()
  const store = useStore()
  const token = store.state.token
  const shopId = computed(() => store.state.shopId)

  function jump(path: string) {
    console.log('jump--->', path)
    router.push(path)
  }

  function pageBack() {
    router.back()
  }

  const shoppingCartSkuList = computed(() => store.state.cart.cart.skus)
  const shoppingCartSkuSize = computed(() => {
    let size = 0
    shoppingCartSkuList.value && shoppingCartSkuList.value.forEach((row) => {
      if (row.shopId === unref(shopId)) {
        size++
      }
    })
    return size
  })



  return {
    jump,
    pageBack,
    token,
    shopId,
    shoppingCartSkuSize,
  }
}
