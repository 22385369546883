import Home from '@/pages/home/Home.vue'
import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Sku from '@/pages/sku/Sku.vue'
import Jump from '@/pages/Jump.vue'
import Cart from '@/pages/cart/Cart.vue'
import ConfirmOrder from '@/pages/confirmOrder/ConfirmOrder.vue'
import AddressList from '@/pages/address/AddressList.vue'
import AdddressAdd from '@/pages/address/AddressAdd.vue'
import My from '@/pages/my/My.vue'
import Order from '@/pages/order/Order.vue'
import OrderDetail from '@/pages/order/OrderDetail.vue'
import Login from '@/pages/login/Login.vue'
export const routes: RouteRecordRaw[] = [
  {path: '/', component: Home},
  {path: '/home', component: Home},
  {path: '/sku', component: Sku},
  {path: '/jump', component: Jump},
  {path: '/cart', component: Cart},
  {
    path: '/confirmOrder', component: ConfirmOrder, meta: {
      keepAlive: true
    }
  },
  {path: '/address/list', component: AddressList},
  {path: '/address/add/:addressId', component: AdddressAdd},
  {path: '/my', component: My},
  {path: '/order', component: Order},
  {path: '/order/detail/:orderNo', component: OrderDetail},
  {path: '/login', component: Login}
]
console.log('router config', routes)
export const router = createRouter({
  history: createWebHashHistory(),
  routes
})
