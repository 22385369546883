<template>
  <div class='view-main'>
    <div class='x-navbar' v-if="!props.noNavbar">
      <slot name="navbar"></slot>
    </div>
    <div class="x-page">
      <slot></slot>
    </div>
    <div class="x-toolbar" v-if="!props.noToolbar">
      <slot name="toolbar" v-if="slots.toolbar"></slot>
      <van-tabbar route v-else>
        <van-tabbar-item replace to="/home" icon="home-o">首页</van-tabbar-item>
        <van-tabbar-item replace to="/cart" icon="cart">购物车</van-tabbar-item>
        <van-tabbar-item replace to="/my" icon="manager">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script lang=ts setup>
import {useSlots} from "vue";

const props = defineProps({
  noToolbar: {
    type: Boolean,
    default: false
  },
  noNavbar: {
    type: Boolean,
    default: false
  }
})
const slots = useSlots()
console.log('useSlots', slots)
</script>

<style lang="scss" scoped type="text/css">
@import "./xpage";
</style>
