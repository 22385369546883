import {computed, unref} from "vue";

export type Fn<T> = () => Promise<T>

export function resolveFn<T>(fn: Fn<T>): Promise<T> {
  return new Promise(async (resolve, reject) => {
    try {
      let res = fn && await fn()
      resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export let isEmptyObject = function (obj) {
  for (let o in obj) {
    return false
  }
  return true
}

export const saveStore = (store) => {
  sessionStorage.setItem('user_store', JSON.stringify(store))
}
