<template>
  <x-page no-toolbar>
    <template v-slot:navbar>
      <van-nav-bar title="订单列表" left-text="返回" @click="pageBack"></van-nav-bar>
    </template>
    <van-tabs v-model:active="active" swipeable>
      <van-tab v-for="(orderType,index) in orderTypeList" :key="index" :title="orderType.text">
        <order-list :orderstatus="orderType.value"></order-list>
      </van-tab>
    </van-tabs>
  </x-page>
</template>

<script lang=ts setup>
import {ref} from "vue";
import {ORDER_STATUS_ALL, ORDER_STATUS_UNPAID, ORDER_STATUS_PAID, ORDER_STATUS_WAIT_RECEIPT} from "@/utils/const";
import OrderList from '@/components/orderList/OrderList.vue';
import {useGlobal} from "@/hook/globalHook";
import {useRoute} from "vue-router";

const {jump, pageBack} = useGlobal()
const route = useRoute()
const active = ref(0)
const orderTypeList = ref([
  {
    text: '全部',
    value: ORDER_STATUS_ALL,
  },
  {
    text: '待支付',
    value: ORDER_STATUS_UNPAID,
  },
  {
    text: '待发货',
    value: ORDER_STATUS_PAID,
  },
  {
    text: '待收货',
    value: ORDER_STATUS_WAIT_RECEIPT,
  },
])
console.log('route.query',route.query)
active.value =parseInt( route.query.idx) || 0
</script>

<style lang="scss" scoped type="text/css">
@import "order";
</style>
