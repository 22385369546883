import { globalConst as native } from '@/utils/const'

var state = {
  listLoading: false,
  provinceLoading: false,
  cityLoading: false,
  actionLoading: false,
  address: [],
  pageIndex: 0,
  provinces: {},
  cities: {},
  noMore: false,
  delLoading: false,
  districtsByAddr: {},
  districts: {},
  checkedAddress: {},
  editAddress: {}
}
var getters = {}
var actions = {}
var mutations = {
  [native.loadProvinceList] (state, data) {
    let bloackList = ['台湾省', '香港特别行政区', '澳门特别行政区']
    data.result = data.result.filter((row) => {
      if (bloackList.indexOf(row.name) !== -1) {
        return false
      } else {
        return true
      }
    })
    state.provinces = data.result
  },
  [native.loadCityList] (state, data) {
    let keyword = data.keyword
    state.cities[keyword]=data.result
  },
  [native.loadDistrictList] (state, data) {
    let keyword = data.keyword
    state.districts[keyword]=data.result
  },
  [native.doDelAddress] (state, keys) {
    state.address = state.address.filter((row) => {
      if (keys.indexOf((row.id).toString()) !== -1) {
        return false
      } else {
        return true
      }
    })
  },
  [native.doAddAddress] (state, data) {
    state.address = []
    state.pageIndex = 0
  },
  [native.cliearAddressList] (state) {
    state.address = []
    state.pageIndex = 0
  },
  setEditAddress (state, addressInfo) {
    state.editAddress = addressInfo
  }
}
export {
  state,
  getters,
  actions,
  mutations
}
