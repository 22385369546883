import {resolveFn} from "@/utils/utils";
import {store} from '@/store';
import axios from 'axios';

const serverUrl = import.meta.env.public_serverUrl

function postRequest<T>(url: string, data = {}): Promise<void | T> {
  let header = {}
  const token = store.state.token
  if (token) {
    header = {
      'Authorization': `Bearer ${token}`
    }
  }
  data.version = '1.0.1'
  data.platform = 'web'
  return new Promise((resolve, reject) => {
    axios.request({
      method: 'POST',
      url: `${url}?${new Date().getTime()}`,
      data,
      headers: header,
    }).then((data) => {
      console.log('axios--->', data)
      if (data.data.success) {
        resolve(data.data.data)
      } else {
        reject(data.data.message)
      }

    }, reject)
  })
}

export class Api {
  static login(opt: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/login/index`
      return postRequest(url, opt)
    })
  }

  static skuList(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/sku/list`
      return postRequest(url, p)
    })
  }

  static skuDetail(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/sku/detail`
      return postRequest(url, p)
    })
  }

  static skuDetailByIds(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/sku/detail/list`
      return postRequest(url, p)
    })
  }

  static orderBuy(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/order/buy`
      return postRequest(url, p)
    })
  }

  static wxPay(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/order/pay`
      return postRequest(url, p)
    })
  }

  static orderList(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/order/list`
      return postRequest(url, p)
    })
  }

  static orderDetail(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/order/detail`
      return postRequest(url, p)
    })
  }

  static addressList(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/user/address/list`
      return postRequest(url, p)
    })
  }

  static addressAdd(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/user/address/add`
      return postRequest(url, p)
    })
  }

  static addressEdit(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/user/address/edit`
      return postRequest(url, p)
    })
  }

  static addressDel(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/user/address/del`
      return postRequest(url, p)
    })
  }

  static bindMobile(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/user/bindMobile`
      return postRequest(url, p)
    })
  }

  static sendCode(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/login/sendCode`
      return postRequest(url, p)
    })
  }

  static getWechatConfig() {
    return resolveFn(async () => {
      let url = `${serverUrl}/wechat/config`
      return postRequest(url)
    })
  }
  static getUserProfile(){
    return resolveFn(async () => {
      let url = `${serverUrl}/user/profile`
      return postRequest(url)
    })
  }
  static orderReceipt(p: any) {
    return resolveFn(async () => {
      let url = `${serverUrl}/order/receipt`
      return postRequest(url, p)
    })

  }
}
