<template>
    <a class="button" :class="bindClass" @click.stop="_click">
        <slot></slot>
    </a>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: 'red'
      },
      active: {
        type: Boolean,
        default: false
      },
    },
    name: '',
    methods: {
      _click () {
        this.$emit('click')
      }
    },
    computed: {
      bindClass () {
        return {
          ['color-' + this.color]: this.color,
          'active': this.active,
        }
      },

    }
  }
</script>

<style lang="scss" scoped type="text/css">
@import "./mybutton.scss";
</style>