<template>
  <x-page>
    <template v-slot:navbar>
      <van-nav-bar :title="goods && goods.name?goods.name :'商品加载中...'" left-text="返回" @click="pageBack"></van-nav-bar>
    </template>
    <div class='err-panel' v-if="err">
      {{ err }}
    </div>
    <div v-else-if="goods && goods.id>0">
      <section class="sku_detail">
        <img :src="getSkuMediaUrl(goods.id)" class="goods_img" alt="">
        <div class="title"><span class='sku-subtitle'>{{ goods.subtitle }}</span>{{ goods.name }}
        </div>
        <div v-if="goods.remark" class="remark">{{ goods.remark }}</div>
        <div class='remarkcontainer'>
          <div v-if="goods.originalPrice" class='originalprice'>原价：<strike>¥{{
              toFixed(goods.originalPrice)
            }}</strike></div>
          <div class='price'>现价：¥{{ skuPrice }}</div>
          <span class='unit'>{{ goods.unit }}</span>

        </div>
      </section>

      <section class="tagcontainer" v-if="materialList && materialList.length>0">
        <header class="content-block-title">请选择规格</header>
        <van-radio-group v-model="material">
          <van-cell-group inset>
            <van-cell v-for="(item,index) in materialList" :key="index" :title="item" clickable
                      @click="material = index">
              <template #right-icon>
                <van-radio :name="index"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>

      </section>
      <div class='tagcontainer' v-if="goods.tags">
        <span v-for="tag in goods.tags">{{ tag.name }}</span>
      </div>
      <section class="detailcontainer">
        <header class="header">商品详情</header>
        <div v-if="goods.detailPicCount > 0">
          <img v-for="(index,i) in goods.detailPicCount" :key="i" :src="getSkuPicMediaUrl(goods.id,i)" alt=""
               class="goods_img">
        </div>
        <div v-else>
          <p class='text-center' key='nosku'>暂时未有商品详情</p>
        </div>
      </section>
    </div>
    <template v-slot:toolbar>
      <div style='height:var(--van-action-bar-height) '>
        <van-action-bar>
          <van-action-bar-icon :badge="shoppingCartSkuSize" icon="cart-o" @click="onClickCart">
            购物车
          </van-action-bar-icon>
          <van-action-bar-button type="warning" @click="addToCart(1)">
            加入购物车
          </van-action-bar-button>
          <van-action-bar-button type="danger" @click="addToCart(2)">
            立即购买
          </van-action-bar-button>
        </van-action-bar>
      </div>
    </template>
  </x-page>
</template>

<script lang=ts setup>
import {useGlobal, toFixed} from "@/hook/globalHook";
import {onMounted, ref, computed, unref} from "vue";
import {useRouter, useRoute} from "vue-router";
import {Api} from "@/api/api";
import {showToast} from "vant";
import {getSkuMediaUrl, getSkuPicMediaUrl} from '@/hook/skuHook'
import {useStore} from "@/store";
import {globalConst as native} from "@/utils/const";

const router = useRouter()
const route = useRoute()
const store = useStore()
const {jump, pageBack, token, shoppingCartSkuSize, shopId} = useGlobal()
const goods = ref({
  id: 0,
  name: "",
  subtitle: '',
  price: 0,
  originalPrice: 0,
  material: '',
  mainPicCount: 0,
  putaway: 1,
  detailPicCount: 0,
  remark: '',
  matPrice: [0],
  unit: '',
  tags: []
})
const material = ref(0)
const skuId = route.query.skuId
const err = ref<null | string>(null)
const initData = async () => {
  if (!token) {
    err.value = '请先登录'
    return
  }
  let result = await Api.skuDetail({
    skuId: skuId,
    shopId: unref(shopId)
  })
  goods.value = result.detail
  goods.value.tags = result.tags
}
onMounted(async () => {
  await initData()
})

const skuPrice = computed(() => {
  let price = goods.value.defprice || goods.value.price
  return toFixed(price + (goods.value.matPrice && goods.value.matPrice.length > 0 && goods.value.matPrice[material.value] ? goods.value.matPrice[material.value] : 0))
})
const materialList = computed(() => {
  if (goods.value.material.trim().length === 0) {
    return null
  }
  return goods.value.material.trim().split(',')
})

function addToCart(mode) {
  switch (mode) {
    case 1:
      store.commit('addToCart', {
        skuId: goods.value.id,
        material: unref(material),
        quality: 1,
        model: 'append',
        shopId: unref(shopId)
      })
      showToast('加入购物车成功~')
      break
    case 2:
      store.commit('quickBuyTarget', {
        skuId: goods.value.id,
        material: unref(material),
        quality: 1,
        shopId: unref(shopId)
      })
      console.log('path /confirmOrder?quickBuy=1')
      jump(`/confirmOrder?quickBuy=1&t=${Math.random()}`)
      break
  }
}

function formatPrice(price) {
  return "¥" + (price / 100).toFixed(2);
}

function onClickCart() {
  router.push("cart");
}

function sorry() {
  showToast("暂无后续逻辑~");
}
</script>

<style lang="scss">
@import "./sku";
</style>
