<template>
  <x-page>
    <template v-slot:navbar>
      <van-nav-bar title="确认订单" left-text="返回" left-arrow @click-left="pageBack"/>
    </template>
    <div class='comfirmOrder'>
      <section class="contact-info" @click="chooseAddress" v-if="addressByTop10.length>0 || checkedAddress.id===-2">
        <p>
          <span class="name">{{ checkedAddress.contact }}</span>
          <span>{{ checkedAddress.mobile }}</span>
        </p>
        <div class="address">
          <div v-if='checkedAddress.default'>
            <span>默认</span>
          </div>
          <p>{{ checkedAddress.fullAddress }}</p>
        </div>
      </section>
      <list class="noAddress" v-else>
        <van-field clickable @click="chooseAddress" label="设置收货地址" readonly>
        </van-field>
      </list>
      <div class="line-10"></div>
      <section class="pay-list">
        <div>
          <pay-item v-for="(sku,index) in checkedSkuList"
                    :key="index"
                    :sku="sku"
                    :name="sku.name"
                    :price="sku.skuprice"
                    :sku-id="sku.skuId"
                    :quantity="sku.quality"
                    :material="sku.arrMater?sku.arrMater[sku.material]:''"
                    :media-url="getSkuMediaUrl(sku.skuId)"></pay-item>
        </div>
      </section>
      <div class="line-10"></div>
      <section class='pay-footer' v-if="renderPrice && renderPrice.canBuy && !orderError">
        <f7-block-title></f7-block-title>
        <van-cell-group>
          <van-field class='input-remark' v-model='remark' label='买家留言'
                     placeholder='点击给商家留言'></van-field>
          <van-field label="商品金额" :model-value="`¥${totalPrice}`" input-align="right" readonly>
          </van-field>
          <!--        label="运费123"-->
          <van-field label="运费">
            <template v-slot:input>
              <div style='width:100%;text-align: right'>
                <template v-if="renderPrice.logisticPrice>0">
                  ¥ {{ renderPrice.logisticPrice | toFixed }}
                </template>
                <template v-else>
                  包邮
                </template>
              </div>
            </template>
          </van-field>
        </van-cell-group>
      </section>
      <section v-else-if="!renderPrice.canBuy " class='msg'>
        <p>{{ renderPrice.canBuyMessage }}</p>
      </section>
      <section class='msg' v-if='orderError'>
        <p>错误：{{ orderError }}</p>
      </section>
    </div>
    <template v-slot:toolbar>
      <section class="totalPrice" v-if="renderPrice && renderPrice.canBuy && !orderError ">
        <span>实付:</span>
        <span class="total">¥<strong>{{ renderPrice.totalPay }}</strong></span>
        <div class="pay-btn" @click="verfiyPay">提交订单</div>
      </section>
    </template>

    <van-popup v-model:show="showPopup" position="bottom">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-inner">
              <div class="title">请选择收货地址</div>
              <div class="right">
              </div>
            </div>
          </div>
          <div class="page-content">
            <section class="address-info">
              <div class="container" v-for="(address,index) in addressByTop10" :key="index"
                   @click="checkAddress(address)">
                <div class="name-wrapper">
                  <p class="name">{{ address.contact }}</p>
                </div>
                <div class="moblie-wrapper">
                  <div class="mobile">
                    {{ address.mobile }}
                    <span class="default" v-if="address.default===1">默认</span>
                  </div>
                  <p class="address">{{ address.fullAddress }}</p>
                </div>
                <div class="edit" @click.stop="editAddress(address.id,address)">
                </div>
              </div>
            </section>
          </div>
          <div class='coupon-list-footer'>
            <div @click="editAddress(-1)" class='btn-add-address'>+ 新增地址</div>
          </div>
        </div>
      </div>
    </van-popup>
  </x-page>
</template>

<script lang=ts setup>

import {useRoute, useRouter} from "vue-router";
import {useStore} from "@/store";
import {computed, onMounted, ref, watch, unref, onActivated} from "vue";
import {Api} from "@/api/api";
import {useGlobal, getMediaUrl, toFixed} from "@/hook/globalHook";
import {getSkuMediaUrl} from "@/hook/skuHook";
import {showToast} from "vant";
import PayItem from '@/components/payItem/PayItem.vue';
import {CartSku} from "@/store/modules/cart";
import {isEmptyObject} from "@/utils/utils";
import {globalConst as native, ORDER_STATUS_UNPAID} from "@/utils/const";

const {jump, pageBack, shopId} = useGlobal()
const store = useStore()
const router = useRouter()
const route = useRoute()
const orderError = ref<string | null>(null)
const addressByTop10 = ref([])
const checkedAddress = computed(() => store.state.address.checkedAddress)
const checkedSkuIdxList = computed(() => store.state.cart.checkedSkuIdxList)
let skuMap = {}
onMounted(async () => {
  try {
    if (route.query && route.query.quickBuy) {
      quickBuy.value = !!parseInt(route.query.quickBuy as string, 10)
    }
    // this.loadProfile()
    if (quickBuy.value) {
      oriCheckedSkuList.value.push(quickBuyTarget.value)
    } else {
      console.log('checkedSkuIdxList', unref(checkedSkuIdxList))
      checkedSkuIdxList.value.forEach((checkedSku) => {
        oriCheckedSkuList.value.push(checkedSku)
      })
    }
    // 校验数据
    let skuIds = oriCheckedSkuList.value.map((row) => row.skuId)
    for (let i = 0; i < skuIds.length; i++) {
      let skuId = skuIds[i]
      let skuInfo = await Api.skuDetail({skuId, shopId: unref(shopId)})
      skuMap[skuId] = skuInfo.detail
      store.commit('setSkuDetail', skuInfo)
    }
    checkedSkuList.value = []
    oriCheckedSkuList.value.forEach((row) => {
      let cartSku = new CartSku(row.skuId, row.material, row.quality)
      let skuDetail = skuMap[row.skuId]
      if (skuDetail) {
        cartSku.init(skuDetail)
        // if (skuDetail.status !== 1) {
        //   orderError.value = `${skuDetail.name}${skuDetail.error}`
        // }
        checkedSkuList.value.push(cartSku)
      }
    })
    addressByTop10.value = await Api.addressList({
      index: 0
    })
    if (isEmptyObject(checkedAddress.value)) {
      store.state.address.checkedAddress = addressByTop10.value[0] || {}
    }
    let {salePrice, extraPrice: _eprice, freeExpress: _fexpress, error} = computeTotalPrice()
    if (error) {
      orderError.value = error
      return
    }
    extraPrice.value = _eprice
    freeExpress.value = _fexpress
    totalPrice.value = salePrice
  } catch (e) {
    orderError.value = e
  }
})

const showPopup = ref(false)

function chooseAddress() {
  showPopup.value = true
}


const checkedSkuList = ref<CartSku[]>([])
const oriCheckedSkuList = ref([])
const quickBuy = ref(false)
const quickBuyTarget = computed(() => store.state.cart.quickBuyTarget)

const extraPrice = ref(0)
const freeExpress = ref(1)
const totalPrice = ref(0)
const remark = ref('')

function computeTotalPrice() {
  // const profile = this.profile
  let extraPrice = 0
  let salePrice = 0
  //   1免邮 ，0不免邮
  let freeExpress = 1
  let error: string | null = null
  if (!checkedSkuList.value || checkedSkuList.value.length <= 0) {
    return {
      salePrice,
      extraPrice,
      freeExpress,
      error
    }
  }
  checkedSkuList.value.forEach((row) => {
    let skuprice = 0
    let detail = row.detail
    if (detail) {
      skuprice = detail.price
      // 计算运费价格
      if (detail.freeExpress === 0) {
        freeExpress = 0
      }
      extraPrice += detail.expressPrice * row.quality
      salePrice += (skuprice + (row.matPrice[row.material] || 0)) * row.quality
      row.skuprice = skuprice

      if (detail.expireTime && detail.expireTime < new Date()) {
        error = `抱歉，${detail.name}已超过有效期`
      }

      const arrStock = detail.stock.split(',')
      const stock = arrStock[row.material]
      if (row.quality > parseInt(stock, 10)) {
        const arrMater = detail.material.split(',')
        const material = arrMater[row.material]
        error = `抱歉，${detail.name} ${material}库存不足`
      }
    }
  })
  return {
    salePrice,
    extraPrice,
    freeExpress,
    error
  }
}

const renderPrice = computed(() => {
  // const profile = this.profile
  let couponDiscount = 0
  let inited = true
  let canBuy = true
  let canBuyMessage = ''
  let skuprice = 0


  // 开始组合使用优惠券
  let fullCutRule = ''
  let detail = null
  let price = 0
  let useExpressCoupon = 0


  let {province} = checkedAddress.value
  // 计算邮费
  let logisticPrice = computedExpressPrice(province)

  // 计算应付
  let totalPay = totalPrice.value + logisticPrice
  if (totalPay < 0) {
    totalPay = 0
  }
  if (!canBuy) {
    return {canBuy, canBuyMessage}
  }
  return {
    canBuy,
    logisticPrice,
    totalPay,
    couponDiscount,
  }
})

function computedExpressPrice(city) {
  let expressTmplMap = new Map()
  checkedSkuList.value.forEach((row) => {
    if (row && row.detail) {
      const detail = row.detail
      if (expressTmplMap.has(detail.expressTmpl.id)) {
        let tmpl = expressTmplMap.get(detail.expressTmpl.id)
        tmpl.skuQty += row.quality
        tmpl.skuFee += detail.price * row.quality
        tmpl.skuWeight += detail.weight * row.quality
        tmpl.skuVolume += detail.volume * row.quality
      } else {
        let tmpl = detail.expressTmpl
        tmpl.skuQty = row.quality
        tmpl.skuFee = detail.price * row.quality
        tmpl.skuWeight = detail.weight * row.quality
        tmpl.skuVolume = detail.volume * row.quality
        expressTmplMap.set(detail.expressTmpl.id, Object.assign({}, detail.expressTmpl))
      }
    }
  })
  let expressFee = 0
  for (let tmpl of expressTmplMap.values()) {
    if (tmpl.freeExpress === 1) {
      continue
    }
    let value = 0
    if (tmpl.priceType === 0) {
      // 按件
      value = tmpl.skuQty
    } else if (tmpl.priceType === 1) {
      // 按重量
      value = tmpl.skuWeight
    } else if (tmpl.priceType === 2) {
      // 按体积
      value = tmpl.skuVolume
    }
    tmpl.value = value
    let freeExpress = 0
    if (tmpl.conditional === 1) {
      for (let i = 0; i < tmpl.conditionalItems.length; i++) {
        let conditionalItem = tmpl.conditionalItems[i]
        let cities = conditionalItem.cities.split(',')
        if (cities.indexOf(city) !== -1) {
          if (conditionalItem.mode === 0) {
            if (tmpl.value >= conditionalItem.qty) {
              freeExpress = 1
              break
            }
          } else if (conditionalItem.mode === 1) {
            if (tmpl.skuFee >= conditionalItem.amount) {
              freeExpress = 1
              break
            }
          } else if (conditionalItem.mode === 2) {
            if (tmpl.value >= conditionalItem.qty && tmpl.skuFee >= conditionalItem.amount) {
              freeExpress = 1
              break
            }
          }
        }
      }
    }
    if (freeExpress === 1) {
      continue
    }
    let expressMethod = null
    for (let i = 0; i < tmpl.items.length; i++) {
      let v = tmpl.items[i]
      if (!expressMethod && v.default === 1) {
        expressMethod = v
      }
      if (v.cities && v.cities.length > 0) {
        let cities = v.cities.split(',')
        if (cities.indexOf(city) !== -1) {
          expressMethod = v
          break
        }
      }
    }
    if (expressMethod) {
      let startFee = expressMethod.startFee
      let addQty = value - expressMethod.startStandard
      let addFee = 0
      if (addQty > 0) {
        addFee = Math.ceil(addQty / expressMethod.addStandard) * expressMethod.addFee
      } else {
        if (expressMethod.specialStandard1 !== 0 || expressMethod.specialStandard2 !== 0 || expressMethod.specialStandard3 !== 0) {
          if (value >= 0 && value <= expressMethod.specialStandard1) {
            startFee = expressMethod.specialFee1
          } else if (value > expressMethod.specialStandard1 && value <= expressMethod.specialStandard2) {
            startFee = expressMethod.specialFee2
          } else if (value > expressMethod.specialStandard2 && value <= expressMethod.specialStandard3) {
            startFee = expressMethod.specialFee3
          }
        }
      }
      expressFee += (startFee + addFee)
    }
  }
  return expressFee
}

function editAddress(addressId, address) {
  sessionStorage.setItem('isOpenPopup', '1')
  store.commit('setEditAddress', address)
  router.push({
    path: `/address/add/${addressId}`,
  })
}

function checkAddress(address) {
  store.state.address.checkedAddress = address
  showPopup.value = false
}

onActivated(() => {
  // todo:无效？？？？
  console.log('页面被重新激活~')
})

function verfiyPay() {
  if (isEmptyObject(checkedAddress.value)) {
    showToast('请选择地址')
    return
  }
  let skus = oriCheckedSkuList.value.map((sku) => {
    sku.quality = Math.floor(sku.quality)
    if (sku.quality <= 1) {
      sku.quality = 1
    }
    return sku
  })
  const values = {
    skus: unref(skus),
    addressId: checkedAddress.value.id,
    remark: unref(remark),
    shopId: unref(shopId)
  }
  Api.orderBuy(values).then((res) => {
    let orderNo = res.orderNo
    if (!quickBuy.value) {
      store.commit(native.clearCart)
    }
    store.commit(native.clearOrder)
    store.commit(native.clearDashboard)
    sessionStorage.setItem('orderNo', orderNo)
    if (typeof WeixinJSBridge === 'undefined') {
      showToast('请在微信浏览器支付')
      return
    } else {
      Api.wxPay({
        orderNo: orderNo
      }).then((data) => {
        const wxpayParams = data.pay
        const {timeStamp, nonceStr, signType, paySign} = wxpayParams
        wx.chooseWXPay({
          timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: wxpayParams.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign, // 支付签名
          success: (res) => {
            // 支付成功后的回调函数
            router.push('/order')
          },
          fail: (error) => {
            console.error('error', error)
            showToast(error.errMsg || error || '系统异常')
          },
          cancel: () => {
            router.push(`/order?orderType=${ORDER_STATUS_UNPAID}`)
          }
        })
      }).catch((error) => {
        showToast(error)
      })
    }
  }).catch((err) => {
    console.error('err', err)
    showToast(err)
  })
}


</script>

<style lang="scss" scoped type="text/css">
@import "./confirmOrder";
@import "../address/address";
</style>
