<template>
  <x-page>
    <template v-slot:navbar>
      <van-nav-bar title="订单详情" left-text="返回" @click="pageBack"></van-nav-bar>
    </template>
    <section class="order-detail" v-if="order">
      <div class="address">
        <template v-if="order.surrogate===1">
          收货地址：囤货代发订单
        </template>
        <template v-else>
          <h1>收货地址</h1>
          <p class="name">
            <span>{{ order.contact }}</span>
            <span>{{ order.mobile }}</span>
          </p>
          <p>{{ order.fullAddress }}</p>
        </template>
      </div>
      <div class="order-info">
        <order-item v-for="(sku,idx) in order.skus"
                    :sku="sku"
                    :skuId="sku.skuId"
                    :key="idx"></order-item>
      </div>
      <van-field label="商品总金额" :model-value="`¥${order.price}`" input-align="right" readonly></van-field>
      <van-field label="运费" :model-value="`¥${order.logisticPrice}`" input-align="right" readonly></van-field>
      <van-field v-if="order.discount!==0" label="活动优惠" :model-value="`¥${order.discount}`" input-align="right"
                 readonly></van-field>
      <f7-block-footer class='sku-footer'>
        <div class="total-cost">
          共<strong>{{ order.skus.length }}</strong>件商品&nbsp;&nbsp;应付总额：
          <strong class='unit'>¥</strong><strong class='price'>{{ order.actualprice }}</strong>
        </div>
      </f7-block-footer>

      <van-field label="订单号" :model-value="`${order.orderNo}`" input-align="right" readonly></van-field>
      <template v-if="order.status === ORDER_STATUS_WAIT_RECEIPT || order.status === ORDER_STATUS_FINISH">
        <van-field label="下单时间" :model-value="`¥${order.createTime}`" input-align="right" readonly></van-field>
        <van-field v-if="order.status === ORDER_STATUS_WAIT_RECEIPT || order.status === ORDER_STATUS_FINISH"
                   label="物流公司"
                   :model-value="`${order.logisticName}`" input-align="right" readonly></van-field>
        <van-field label="发货时间" :model-value="`${order.logisticTime}`" input-align="right" readonly></van-field>
      </template>
      <f7-block-footer v-if="order.status === ORDER_STATUS_WAIT_RECEIPT || order.status === ORDER_STATUS_FINISH">
        <div class='logisticNo'><strong>物流单号：</strong>{{ order.logisticNo }}</div>
      </f7-block-footer>
    </section>

    <template v-slot:toolbar>
      <div class='order-detail-footer' v-if="order && (order.status === ORDER_STATUS_UNPAID || order.logisticNo || (order.status === ORDER_STATUS_PAID || order.status === ORDER_STATUS_WAIT_RECEIPT || order.status === ORDER_STATUS_FINISH) && order.surrogate === 1)">
        <my-button
            v-if=" (order.status === ORDER_STATUS_UNPAID) &&  order.createTime+ 30*60*1000 >= order.nowTime"
            active
            @click="onPay(order)">
          立即付款
        </my-button>
        <my-button v-if=" order.status === ORDER_STATUS_WAIT_RECEIPT"
                   active
                   @click="onReceipt(order)">
          确认收货
        </my-button>
      </div>
    </template>
  </x-page>
</template>

<script lang=ts setup>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "@/store";
import {computed, onMounted, ref, watch, unref, onActivated} from "vue";
import {Api} from "@/api/api";
import {useGlobal, getMediaUrl, toFixed} from "@/hook/globalHook";
import {getSkuMediaUrl} from "@/hook/skuHook";
import {showToast} from "vant";
import PayItem from '@/components/payItem/PayItem.vue';
import {isEmptyObject} from "@/utils/utils";
import {
  globalConst as native,
  ORDER_STATUS_UNPAID,
  ORDER_STATUS_ALL,
  ORDER_STATUS_PAID,
  ORDER_STATUS_WAIT_RECEIPT
} from "@/utils/const";
import {useOrder} from '@/hook/orderHook'
import MyButton from "@/components/MyButton/MyButton.vue";
import OrderItem from '@/components/orderList/OrderItem.vue'
import XPage from "@/components/xui/xpage/XPage.vue";

const {jump, pageBack} = useGlobal()
const store = useStore()
const router = useRouter()
const route = useRoute()
const orderNo = route.params.orderNo

const order = ref()

function loadData() {
  Api.orderDetail({
    orderNo
  }).then(res => {
    order.value = res
  })
}

const {onPay, onReceipt} = useOrder()

onMounted(async () => {
  await loadData()
})
</script>

<style lang="scss" scoped type="text/css">
@import "orderDetail";
</style>
