import {getMediaUrl} from "@/hook/globalHook";

export function getSkuMediaUrl(skuId:number){
  return `${getMediaUrl()}sku_main_${skuId}_0.jpg`
}
export function getSkuPicMediaUrl(skuId:number,index){
  return `${getMediaUrl()}sku_detail_${skuId}_${index}.jpg`
}
const useSku = () => {

}
